<template>
  <div class="accounts-balance-main">
    <v-card class="accounts-balance">
      <spark-graph
        :data="balance"
        currency="EUR"
        title="Balance"
      />
    </v-card>
    <v-card class="accounts-sales">
      <spark-graph
        :data="sales"
        currency="EUR"
        title="Sales"
      />
    </v-card>
    <v-card class="accounts-payments">
      <spark-graph
        :data="payments"
        currency="EUR"
        title="Payments"
      />
    </v-card>
  </div>
</template>
<script>
import datas from '../../account/summary/datas.js'

export default {
  components: {
    'spark-graph': () => import('@/components/spark-graph.vue')
  },
  data () {
    return {
      balance: datas.map(a => ({ date: a.date, value: a.balance })),
      sales: datas.map(a => ({ date: a.date, value: a.sales })),
      payments: datas.map(a => ({ date: a.date, value: a.payments }))
    }
  }
}
</script>
<style lang="stylus" scoped>
.accounts-balance-main
  display flex
  flex-wrap wrap

.accounts-balance-main>*
  width 294.3px

.accounts-balance-main>*~*
  margin-left 1em
</style>
